import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import LinkButton from '../../../../elements/linkButton/linkButton.component';
import BannerTitle from '../../components/bannerTitle.component';

const BannerImageContainer = styled.div`
  display: none;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    display: block;
    width: 100%;
  }
`;
const ButtonContainer = styled.div`
  min-width: 327px;
  margin: 24px;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    min-width: 300px;
  }
`;
const BannerImg = styled.img`
  width: 100%;
`;
const BannerContainer = styled.div`
  display: flex;
  max-width: 1440px;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 64px 80px;
  background: ${props => props.theme.colours.nimbleRed};
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    text-align: left;
    flex-direction: column;
    padding: 0;
  }
`;
const BannerCententContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    padding: 24px;
  }
`;

const CarLoanBanner = () => {
  const carLoanPageData = useStaticQuery(graphql`
    {
      contentfulComponentsPage(pageId: { eq: "Nimble Car Loan Landing" }) {
        components {
          ... on ContentfulNimblePageHeadingSection {
            id
            applyNowLink
            benefitPoints
            description
            title
            pointsTitle
            mobileBackgroundImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);
  const {
    applyNowLink,
    description,
    title,
    mobileBackgroundImage: {
      file: { url: mobileBannerUrl }
    }
  } = carLoanPageData.contentfulComponentsPage.components[0];
  return (
    <>
      <BannerContainer>
        <BannerImageContainer>
          <BannerImg src={mobileBannerUrl} alt="Nimble Anytime Banner" />
        </BannerImageContainer>
        <BannerCententContainer>
          <BannerTitle
            title={title}
            description={description}
            titleCenter
          />
          <ButtonContainer>
            <LinkButton
              text="Apply Now"
              color="GREEN"
              link={applyNowLink}
            />
          </ButtonContainer>
        </BannerCententContainer>
      </BannerContainer>
    </>
  );
};

export default CarLoanBanner;
