import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledContainer = styled.div`
  width: 820px;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    width: 100%;
  }
`;
const StyledTitle = styled.h1`
  font-style: normal;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  text-transform: none;
  white-space: pre-wrap;
  text-align: ${props => (props.titleCenter ? 'center' : 'left')};
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    font-size: 36px;
    line-height: 40px;
  }
`;
const StyledSubtitle = styled.h3`
  padding-top: ${props => (props.subtitle ? '16px' : 0)};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    font-size: 16px;
    line-height: 20px;
  }
`;
const StyledDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
  padding: ${props => (props.description ? '8px 0' : 0)};
  text-align: ${props => (props.descriptionCenter ? 'center' : 'left')};
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
`;
const BannerTitle = ({
  title,
  subtitle,
  description,
  titleCenter,
  descriptionCenter
}) => {
  return (
    <StyledContainer>
      <StyledTitle titleCenter={titleCenter}>{title}</StyledTitle>
      <StyledSubtitle subtitle={subtitle}>{subtitle}</StyledSubtitle>
      <StyledDescription
        description={description}
        descriptionCenter={descriptionCenter}
      >
        {description}
      </StyledDescription>
    </StyledContainer>
  );
};

export default BannerTitle;

BannerTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  titleCenter: PropTypes.bool,
  descriptionCenter: PropTypes.bool
};
