/* eslint-disable react/prop-types */
import React from 'react';
import LargeLoanBanner from './components/carLoanBanner.component';
import ProofPoints from '../../../components/proofPoints';
import TestimonialsComponent from '../../../components/testimonials';
import FAQComponent from '../../../components/faq';
import EndOfPage from '../components/endOfPage/endOfPage.component';

const NimbleCarLoan = ({ componentData }) => {
  const proofPointsData = componentData.filter(
    data => data.__typename === 'ContentfulComponentProofPoints'
  )[0];
  const testimonialsData = componentData.filter(
    data => data.__typename === 'ContentfulComponentTestimonials'
  )[0];
  const FAQsData = componentData.filter(
    data => data.__typename === 'ContentfulComponentFaq'
  )[0];
  return (
    <>
      <LargeLoanBanner />
      {proofPointsData && <ProofPoints data={proofPointsData} />}
      {testimonialsData && <TestimonialsComponent data={testimonialsData} />}
      {FAQsData && <FAQComponent data={FAQsData} />}
      <EndOfPage applyLink="/cxindex.html" applyName="Nimble Personal Loan" />
    </>
  );
};

export default NimbleCarLoan;
